import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery, Link } from 'gatsby'
import ReadMoreLink from '../general/ReadMoreLink'

const GalleryRollTemplate = (props) => {
  const { data = {}, count } = props
  const { allStrapiArticle: { edges: posts = [] } = {} } = data
  const postsToShow = count ? posts.slice(0, count) : posts

  return (
    <>
      {postsToShow &&
        postsToShow.map(({ node: post }) => (
          <section
            className="card has-text-black is-light margin-bottom-20"
            key={post.slug}
          >
            <div className="card-content">
              <div className="columns">
                <div className="column is-3">
                  <Link to={post.slug}>
                    <figure className="image is-4by5">
                      <img
                        src={post.image && post.image.url}
                        alt={post.author && post.author.name}
                      />
                    </figure>
                  </Link>
                </div>
                <div className="column is-9">
                  <Link
                    to={`/${post.slug}`}
                    className="content is-size-4 has-text-link"
                  >
                    {post.title}
                  </Link>
                  <p>{post.excerpt}</p>
                  <div className="has-text-left" style={{ marginTop: 20 }}>
                    <ReadMoreLink link={post.slug}>شاهد المعرض</ReadMoreLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
    </>
  )
}

const GalleryRoll = (props) => (
  <StaticQuery
    query={graphql`
      query MyQuery {
        allStrapiArticle(
          limit: 20
          filter: { template: { eq: "gallery" } }
          sort: { order: DESC, fields: publishDate }
        ) {
          edges {
            node {
              title
              body
              slug
              excerpt
              author {
                name
              }
              image {
                url
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <GalleryRollTemplate data={data} count={count} {...props} />
    )}
  />
)

GalleryRollTemplate.propTypes = {
  data: PropTypes.shape({
    allStrapiArticle: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  count: PropTypes.number,
}

export default GalleryRoll
