import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import PageHeader from '../components/general/PageHeader'
import Gallery from '../components/articles/GalleryRoll'

export const GalleryPageTemplate = ({ frontmatter }) => {
  const { title, description, featuredimage: featuredImage } = frontmatter

  const image = featuredImage.childImageSharp
    ? featuredImage.childImageSharp.fluid.src
    : featuredImage

  return (
    <>
      <Helmet titleTemplate="%s | مركز الدراسات السودانية">
        <title>{`${title}`}</title>
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="ar_TN" />
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:image" content={image} />
      </Helmet>

      <PageHeader className="hassan_3" backgroundImage={image}>
        {title}
      </PageHeader>

      <div className="container">
        <div className="section">
          <div className="columns is-multiline">
            <div className="column is-full">
              <div
                dangerouslySetInnerHTML={{ __html: description }}
                className="has-text-centered has-text-weight-bold"
              ></div>
            </div>
            <div className="column is-full">
              <Gallery />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

GalleryPageTemplate.propTypes = {
  frontmatter: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    featuredimage: PropTypes.string,
  }),
}

const GalleryPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <GalleryPageTemplate frontmatter={post.frontmatter} />
    </Layout>
  )
}

GalleryPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GalleryPage

export const galleryPageQuery = graphql`
  query ArtHomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        featuredimage
      }
    }
  }
`
